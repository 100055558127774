:root{
  --primary : rgb(117, 46, 250);
  --color2: #100050;
}
body{
  background-color: #f9f9f9;
  font-family: 'Poppins', sans-serif;
  color: var(--color2);
}
.max_width{
  max-width: 700px;
}
.body_container {
  margin: 0 auto;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.header {
  text-align: center;
  margin-bottom: 30px;
  background-color: #fff;
}

.logo {
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  display: flex;
  padding: 10px 0;
}

.logo img {
  max-height: 50px;
  margin-right: 10px;
}
.logo h1{
  font-size: 27px;
}


.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-control {
  width: 100%;
  padding: 13px 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
}

.btn {
  padding: 15px 15px;
  border: none;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}
.btn:nth-of-type(1){
  color: var(--color2);
}

.preview-btn {
  background-color: #97abc6;
}

.submit-btn {
  background-color: var(--primary)
}

.footer {
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.footer input[type="checkbox"] {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.footer label{
  font-weight: bold;
  cursor: pointer;
}
select{
  cursor: pointer;
}


button[disabled]{
  cursor: not-allowed;
  background-color: rgb(187, 167, 247); /* Lighter and less saturated */
  color: white;
  opacity: 0.6; /* Adding some transparency to imply disabled state */
}
.Toastify__toast-body div{
  font-weight: bold;
  color: var(--color2);
}


/* Modal styling */


.close-btn {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close-btn:hover,
.close-btn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


/* email template design end*/
/* Preview Email Styling */

.modal-content {
  padding: 20px;
}
.preview-header, .preview-footer {
  text-align: center;
  background-color: #fff;
}


.order-status-text {
  color: #5c5ce6;
  font-weight: bold;
}
.contact-options{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.contact-options .contact-btn {
  margin: 10px;
  padding: 10px;
  background-color: #fff;
  color: var(--color2);
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
}
.contact-options .contact-btn img{
  width: 40px;
}

.company-info {
  margin-top: 20px;
  font-size: 12px;
}
.preview-logo{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.preview-logo img{
  max-width: 100%;
}
.preview-logo .main_logo{
  max-width: 170px;
}
.order-status h2{
  background-color: var(--primary);
  color: #fff;
  padding:  10px 0;
}
span.close-btn{
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  background-color:  var(--color2);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
span.close-btn:hover{
  color: #fff;
}
.mail_content{
  background-color: #f5f6f8;
  padding: 20px;
}
.preview-body{
  background-color: #fff;
}
.card_header h3{
  padding: 10px 20px;
  margin: 0px;
  background-color: #dce1e7;
}
.card_body{
  padding: 20px 20px;
}
.card_body p{
  margin: 0;
}
p.warning{
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #dce1e7;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.order-status h3{
  margin-bottom: 0;
  padding-bottom: 20px;
}
.quill-editor .ql-container {
  height: 200px; /* Set the height you want */
  overflow-y: auto; /* Allow vertical scrolling if content exceeds height */
}
p.warning img{
  max-width: 30px;
}
.next-steps p, .next-steps h4{
  margin: 0;
  padding: 0;
}
.next-steps .please_contact{
  margin-top: 15px;
  margin-bottom: 20px;
}

/* email template design end*/


.desktop_modal .model_content_wrap{
  padding-top: 0 !important;
}

@media only screen and (min-width: 767px){
  .form_modal{
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 60px;
  }
  .desktop_modal{
    display: block;
    width: 700px;
  }
  .modal-content{
    padding-top: 0
  }
  .body_container{
    width: 700px;
    margin: unset;
  }
  .desktop_modal .table_wrap{
    max-height: calc(100vh - 150px);
    overflow-y: scroll;
    position: fixed;
  }


}


@media only screen and (max-width: 767px){
  .desktop_modal{
    display: none;
  }
}


/**login css*

/* Container for the login page */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 97vh;
  overflow: hidden;
}

/* Styling for the login box */
.login-box {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 380px;
}

/* Logo section styling */
.login-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}


.logo-letter {
  font-size: 24px;
  color: white;
  font-weight: bold;
}

.company-name h2 {
  font-size: 20px;
  margin: 0;
  font-weight: bold;
  color: #1f2937;
}

.company-name p {
  font-size: 12px;
  margin: 0;
  color: #9ca3af;
}

/* Title above input fields */
.login-title {
  font-size: 20px;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 20px;
}

/* Form input field styling */
.form-group {
  margin-bottom: 16px;
}

.login-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  font-size: 14px;
  color: #1f2937;
  box-sizing: border-box;
}

.login-input::placeholder {
  color: #9ca3af;
}

/* Login button styling */
.login-button {
  width: 100%;
  padding: 12px;
  background-color: var(--primary);
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



/* Error message styling */
.login-error {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}



/* App.css */

/* Dropdown Menu */
.menu-icon {
  position: relative;
  display: inline-block;
}

.dropdown-menu {
  display: inline-block;
  position: absolute;
  top: 84px; /* Position it below the hamburger icon */
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-menu button {
  color: var(--color2);
  padding: 16px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.dropdown-menu button:hover {
  background-color: #f1f1f1;
}



/* Add this CSS to your project for the wave-style loader */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.wave {
  display: flex;
}

.wave div {
  width: 5px;
  height: 30px;
  margin: 3px;
  background: #333;
  animation: wave 1.2s linear infinite;
  border-radius: 10px;
}

.wave div:nth-child(2) {
  animation-delay: -1.1s;
}

.wave div:nth-child(3) {
  animation-delay: -1s;
}

.wave div:nth-child(4) {
  animation-delay: -0.9s;
}

@keyframes wave {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
  }
}


/* TableStyles.css */
.email_logs_table table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
}

.email_logs_table table thead th {
  background-color: #f2f2f2;
  border-bottom: 2px solid #ddd;
  padding: 10px;
}

.email_logs_table table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.email_logs_table table tbody td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  background-color: #7230fa;
  color: white;
  cursor: pointer;
}

button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}
.email_logs_container .table_wrapper{
  padding: 0 60px;
}
.search_emails{
  box-sizing: border-box;
}



